import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e2c39f3c = () => interopDefault(import('../pages/aktie.vue' /* webpackChunkName: "pages/aktie" */))
const _3ce602c0 = () => interopDefault(import('../pages/aktie/_share/index.vue' /* webpackChunkName: "pages/aktie/_share/index" */))
const _17668212 = () => interopDefault(import('../pages/aktie/_share/fundamentaldaten/_tab_id.vue' /* webpackChunkName: "pages/aktie/_share/fundamentaldaten/_tab_id" */))
const _2cc815a6 = () => interopDefault(import('../pages/feature-portfolio-automatisch-importieren.vue' /* webpackChunkName: "pages/feature-portfolio-automatisch-importieren" */))
const _3f42ac79 = () => interopDefault(import('../pages/feedback.vue' /* webpackChunkName: "pages/feedback" */))
const _443d0bb6 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _55f38dcf = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _46c77ccc = () => interopDefault(import('../pages/market-analysis/index.vue' /* webpackChunkName: "pages/market-analysis/index" */))
const _32cf4936 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _740c03d2 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _311135e2 = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _719af53f = () => interopDefault(import('../pages/user-mgmt.vue' /* webpackChunkName: "pages/user-mgmt" */))
const _064432b1 = () => interopDefault(import('../pages/checkout/success/index.vue' /* webpackChunkName: "pages/checkout/success/index" */))
const _62ec7205 = () => interopDefault(import('../pages/feature/individuelle-assets.vue' /* webpackChunkName: "pages/feature/individuelle-assets" */))
const _75afa05b = () => interopDefault(import('../pages/finance/dashboard.vue' /* webpackChunkName: "pages/finance/dashboard" */))
const _509562f0 = () => interopDefault(import('../pages/finance/dashboard/_tab_id.vue' /* webpackChunkName: "pages/finance/dashboard/_tab_id" */))
const _764d410f = () => interopDefault(import('../pages/finance/portfolio.vue' /* webpackChunkName: "pages/finance/portfolio" */))
const _6ae28052 = () => interopDefault(import('../pages/finance/portfolio/index.vue' /* webpackChunkName: "pages/finance/portfolio/index" */))
const _5fb91434 = () => interopDefault(import('../pages/finance/portfolio/_portfolio_id/_tab_id.vue' /* webpackChunkName: "pages/finance/portfolio/_portfolio_id/_tab_id" */))
const _eb0b3300 = () => interopDefault(import('../pages/signup/success.vue' /* webpackChunkName: "pages/signup/success" */))
const _16038c9f = () => interopDefault(import('../pages/checkout/create/_product_id/index.vue' /* webpackChunkName: "pages/checkout/create/_product_id/index" */))
const _3f6e54c4 = () => interopDefault(import('../pages/market-analysis/_tab_id.vue' /* webpackChunkName: "pages/market-analysis/_tab_id" */))
const _1439d70e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de/aktie/",
    component: _e2c39f3c,
    pathToRegexpOptions: {"strict":true},
    name: "aktie",
    children: [{
      path: ":share?/",
      component: _3ce602c0,
      pathToRegexpOptions: {"strict":true},
      name: "aktie-share"
    }, {
      path: ":share?/fundamentaldaten/:tab_id?/",
      component: _17668212,
      pathToRegexpOptions: {"strict":true},
      name: "aktie-share-fundamentaldaten-tab_id"
    }]
  }, {
    path: "/de/virtual/aktie/",
    component: _e2c39f3c,
    pathToRegexpOptions: {"strict":true},
    name: "aktie",
    children: [{
      path: ":share?/",
      component: _3ce602c0,
      pathToRegexpOptions: {"strict":true},
      name: "virtual-aktie-share"
    }, {
      path: ":share?/fundamentaldaten/:tab_id?/",
      component: _17668212,
      pathToRegexpOptions: {"strict":true},
      name: "virtual-aktie-share-fundamentaldaten-tab_id"
    }]
  }, {
    path: "/en/stock/",
    component: _e2c39f3c,
    pathToRegexpOptions: {"strict":true},
    name: "stock",
    children: [{
      path: ":share?/",
      component: _3ce602c0,
      pathToRegexpOptions: {"strict":true},
      name: "stock-share"
    }, {
      path: ":share?/fundamentaldata/:tab_id?/",
      component: _17668212,
      pathToRegexpOptions: {"strict":true},
      name: "stock-share-fundamentaldata-tab_id"
    }]
  }, {
    path: "/en/sandbox/stock/",
    component: _e2c39f3c,
    pathToRegexpOptions: {"strict":true},
    name: "stock",
    children: [{
      path: ":share?/",
      component: _3ce602c0,
      pathToRegexpOptions: {"strict":true},
      name: "sandbox-stock-share"
    }, {
      path: ":share?/fundamentaldata/:tab_id?/",
      component: _17668212,
      pathToRegexpOptions: {"strict":true},
      name: "sandbox-stock-share-fundamentaldata-tab_id"
    }]
  }, {
    path: "/es/compartir/",
    component: _e2c39f3c,
    pathToRegexpOptions: {"strict":true},
    name: "compartir",
    children: [{
      path: ":share?/",
      component: _3ce602c0,
      pathToRegexpOptions: {"strict":true},
      name: "compartir-accion"
    }, {
      path: ":share?/datos-fundamentales/:tab_id?/",
      component: _17668212,
      pathToRegexpOptions: {"strict":true},
      name: "compartir-accion-datos-fundamentales-tab_id"
    }]
  }, {
    path: "/es/salvadera/compartir/",
    component: _e2c39f3c,
    pathToRegexpOptions: {"strict":true},
    name: "compartir",
    children: [{
      path: ":share?/",
      component: _3ce602c0,
      pathToRegexpOptions: {"strict":true},
      name: "salvadera-compartir-accion"
    }, {
      path: ":share?/datos-fundamentales/:tab_id?/",
      component: _17668212,
      pathToRegexpOptions: {"strict":true},
      name: "salvadera-compartir-accion-datos-fundamentales-tab_id"
    }]
  }, {
    path: "/fr/partager/",
    component: _e2c39f3c,
    pathToRegexpOptions: {"strict":true},
    name: "partager",
    children: [{
      path: ":share?/",
      component: _3ce602c0,
      pathToRegexpOptions: {"strict":true},
      name: "partager-action"
    }, {
      path: ":share?/donnees-fondamentales/:tab_id?/",
      component: _17668212,
      pathToRegexpOptions: {"strict":true},
      name: "partager-action-donnees-fondamentales-tab_id"
    }]
  }, {
    path: "/fr/virtuel/partager/",
    component: _e2c39f3c,
    pathToRegexpOptions: {"strict":true},
    name: "partager",
    children: [{
      path: ":share?/",
      component: _3ce602c0,
      pathToRegexpOptions: {"strict":true},
      name: "virtuel-partager-action"
    }, {
      path: ":share?/donnees-fondamentales/:tab_id?/",
      component: _17668212,
      pathToRegexpOptions: {"strict":true},
      name: "virtuel-partager-action-donnees-fondamentales-tab_id"
    }]
  }, {
    path: "/de/feature-portfolio-automatisch-importieren/",
    component: _2cc815a6,
    pathToRegexpOptions: {"strict":true},
    name: "feature-portfolio-automatisch-importieren"
  }, {
    path: "/en/feature-portfolio-auto-import/",
    component: _2cc815a6,
    pathToRegexpOptions: {"strict":true},
    name: "feature-portfolio-auto-import"
  }, {
    path: "/es/importacion-automatica-de-cartera-de-caracteristicas/",
    component: _2cc815a6,
    pathToRegexpOptions: {"strict":true},
    name: "característica-portafolio-automático-importar"
  }, {
    path: "/fr/importation-automatique-du-portefeuille-de-fonctionnalités/",
    component: _2cc815a6,
    pathToRegexpOptions: {"strict":true},
    name: "fonctionnalite-portefeuille-automatique-importer"
  }, {
    path: "/de/feedback/",
    component: _3f42ac79,
    pathToRegexpOptions: {"strict":true},
    name: "feedback"
  }, {
    path: "/en/feedback/",
    component: _3f42ac79,
    pathToRegexpOptions: {"strict":true},
    name: "feedback"
  }, {
    path: "/es/retroalimentacion/",
    component: _3f42ac79,
    pathToRegexpOptions: {"strict":true},
    name: "retroalimentacion"
  }, {
    path: "/fr/retour/",
    component: _3f42ac79,
    pathToRegexpOptions: {"strict":true},
    name: "retour"
  }, {
    path: "/de/anmelden/",
    component: _443d0bb6,
    pathToRegexpOptions: {"strict":true},
    name: "anmelden"
  }, {
    path: "/en/login/",
    component: _443d0bb6,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/es/acceso/",
    component: _443d0bb6,
    pathToRegexpOptions: {"strict":true},
    name: "acceso"
  }, {
    path: "/fr/se-connecter/",
    component: _443d0bb6,
    pathToRegexpOptions: {"strict":true},
    name: "se-connecter"
  }, {
    path: "/de/wartung/",
    component: _55f38dcf,
    pathToRegexpOptions: {"strict":true},
    name: "wartung"
  }, {
    path: "/en/maintenance/",
    component: _55f38dcf,
    pathToRegexpOptions: {"strict":true},
    name: "maintenance"
  }, {
    path: "/es/mantenimiento/",
    component: _55f38dcf,
    pathToRegexpOptions: {"strict":true},
    name: "mantenimiento"
  }, {
    path: "/fr/maintenance/",
    component: _55f38dcf,
    pathToRegexpOptions: {"strict":true},
    name: "maintenance"
  }, {
    path: "/de/marktanalyse/",
    component: _46c77ccc,
    pathToRegexpOptions: {"strict":true},
    name: "market-analysis"
  }, {
    path: "/en/market-analysis/",
    component: _46c77ccc,
    pathToRegexpOptions: {"strict":true},
    name: "market-analysis"
  }, {
    path: "/es/analisis-de-mercado/",
    component: _46c77ccc,
    pathToRegexpOptions: {"strict":true},
    name: "market-analysis"
  }, {
    path: "/fr/analyse-de-marche/",
    component: _46c77ccc,
    pathToRegexpOptions: {"strict":true},
    name: "market-analysis"
  }, {
    path: "/de/profil/",
    component: _32cf4936,
    pathToRegexpOptions: {"strict":true},
    name: "profil"
  }, {
    path: "/en/profile/",
    component: _32cf4936,
    pathToRegexpOptions: {"strict":true},
    name: "profile"
  }, {
    path: "/es/perfil/",
    component: _32cf4936,
    pathToRegexpOptions: {"strict":true},
    name: "perfil"
  }, {
    path: "/fr/profil/",
    component: _32cf4936,
    pathToRegexpOptions: {"strict":true},
    name: "profil"
  }, {
    path: "/de/einstellungen/",
    component: _740c03d2,
    pathToRegexpOptions: {"strict":true},
    name: "einstellungen"
  }, {
    path: "/en/settings/",
    component: _740c03d2,
    pathToRegexpOptions: {"strict":true},
    name: "settings"
  }, {
    path: "/es/configuracion/",
    component: _740c03d2,
    pathToRegexpOptions: {"strict":true},
    name: "configuracion"
  }, {
    path: "/fr/parametres/",
    component: _740c03d2,
    pathToRegexpOptions: {"strict":true},
    name: "parametres"
  }, {
    path: "/de/registrieren/",
    component: _311135e2,
    pathToRegexpOptions: {"strict":true},
    name: "registrieren"
  }, {
    path: "/signup/",
    component: _311135e2,
    pathToRegexpOptions: {"strict":true},
    name: "signup"
  }, {
    path: "/es/registrarse/",
    component: _311135e2,
    pathToRegexpOptions: {"strict":true},
    name: "registrarse"
  }, {
    path: "/fr/inscription/",
    component: _311135e2,
    pathToRegexpOptions: {"strict":true},
    name: "inscription"
  }, {
    path: "/de/user-mgmt/",
    component: _719af53f,
    pathToRegexpOptions: {"strict":true},
    name: "benutzer-mgmt"
  }, {
    path: "/en/user-mgmt/",
    component: _719af53f,
    pathToRegexpOptions: {"strict":true},
    name: "user-mgmt"
  }, {
    path: "/es/user-mgmt/",
    component: _719af53f,
    pathToRegexpOptions: {"strict":true},
    name: "usuario-mgmt"
  }, {
    path: "/fr/user-mgmt/",
    component: _719af53f,
    pathToRegexpOptions: {"strict":true},
    name: "utilisateur-mgmt"
  }, {
    path: "/de/kasse/erfolg/",
    component: _064432b1,
    pathToRegexpOptions: {"strict":true},
    name: "kasse-erfolg"
  }, {
    path: "/en/checkout/success/",
    component: _064432b1,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-success"
  }, {
    path: "/es/caja/exito/",
    component: _064432b1,
    pathToRegexpOptions: {"strict":true},
    name: "caja-exito"
  }, {
    path: "/fr/caisse/succes/",
    component: _064432b1,
    pathToRegexpOptions: {"strict":true},
    name: "caisse-succes"
  }, {
    path: "/de/feature/individuelle-assets/",
    component: _62ec7205,
    pathToRegexpOptions: {"strict":true},
    name: "feature-individuelle-assets"
  }, {
    path: "/en/feature/individual-assets/",
    component: _62ec7205,
    pathToRegexpOptions: {"strict":true},
    name: "feature-individual-assets"
  }, {
    path: "/es/característica/activos-individuales/",
    component: _62ec7205,
    pathToRegexpOptions: {"strict":true},
    name: "característica-individual-activos"
  }, {
    path: "/fr/fonctionnalite/actifs-individuels/",
    component: _62ec7205,
    pathToRegexpOptions: {"strict":true},
    name: "fonctionnalite-individuel-actifs"
  }, {
    path: "/de/finanzen/uebersicht/",
    component: _75afa05b,
    pathToRegexpOptions: {"strict":true},
    name: "finanzen-uebersicht",
    children: [{
      path: ":tab_id?/",
      component: _509562f0,
      pathToRegexpOptions: {"strict":true},
      name: "finanzen-uebersicht-tab_id"
    }]
  }, {
    path: "/de/virtual/uebersicht/",
    component: _75afa05b,
    pathToRegexpOptions: {"strict":true},
    name: "virtual-uebersicht",
    children: [{
      path: ":tab_id?/",
      component: _509562f0,
      pathToRegexpOptions: {"strict":true},
      name: "virtual-uebersicht-tab_id"
    }]
  }, {
    path: "/en/finance/dashboard/",
    component: _75afa05b,
    pathToRegexpOptions: {"strict":true},
    name: "finance-dashboard",
    children: [{
      path: ":tab_id?/",
      component: _509562f0,
      pathToRegexpOptions: {"strict":true},
      name: "finance-dashboard-tab_id"
    }]
  }, {
    path: "/en/sandbox/dashboard/",
    component: _75afa05b,
    pathToRegexpOptions: {"strict":true},
    name: "sandbox-dashboard",
    children: [{
      path: ":tab_id?/",
      component: _509562f0,
      pathToRegexpOptions: {"strict":true},
      name: "sandbox-dashboard-tab_id"
    }]
  }, {
    path: "/es/finanzas/tablero/",
    component: _75afa05b,
    pathToRegexpOptions: {"strict":true},
    name: "finanzas-tablero",
    children: [{
      path: ":tab_id?/",
      component: _509562f0,
      pathToRegexpOptions: {"strict":true},
      name: "finanzas-tablero-tab_id"
    }]
  }, {
    path: "/es/salvadera/tablero/",
    component: _75afa05b,
    pathToRegexpOptions: {"strict":true},
    name: "salvadera-tablero",
    children: [{
      path: ":tab_id?/",
      component: _509562f0,
      pathToRegexpOptions: {"strict":true},
      name: "salvadera-tablero-tab_id"
    }]
  }, {
    path: "/fr/finance/tableau-de-bord/",
    component: _75afa05b,
    pathToRegexpOptions: {"strict":true},
    name: "finance-tableau-de-bord",
    children: [{
      path: ":tab_id?/",
      component: _509562f0,
      pathToRegexpOptions: {"strict":true},
      name: "finance-tableau-de-bord-tab_id"
    }]
  }, {
    path: "/fr/virtuel/tableau-de-bord/",
    component: _75afa05b,
    pathToRegexpOptions: {"strict":true},
    name: "virtuel-tableau-de-bord",
    children: [{
      path: ":tab_id?/",
      component: _509562f0,
      pathToRegexpOptions: {"strict":true},
      name: "virtuel-tableau-de-bord-tab_id"
    }]
  }, {
    path: "/de/finanzen/portfolio/",
    component: _764d410f,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/de",
      component: _6ae28052,
      pathToRegexpOptions: {"strict":true},
      name: "finanzen-portfolio"
    }, {
      path: ":portfolio_id/:tab_id?/",
      component: _5fb91434,
      pathToRegexpOptions: {"strict":true},
      name: "finanzen-portfolio-portfolio_id-tab_id"
    }]
  }, {
    path: "/de/virtual/portfolio/",
    component: _764d410f,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/de",
      component: _6ae28052,
      pathToRegexpOptions: {"strict":true},
      name: "virtual-portfolio"
    }, {
      path: ":portfolio_id/:tab_id?/",
      component: _5fb91434,
      pathToRegexpOptions: {"strict":true},
      name: "virtual-portfolio-portfolio_id-tab_id"
    }]
  }, {
    path: "/en/finance/portfolio/",
    component: _764d410f,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/en",
      component: _6ae28052,
      pathToRegexpOptions: {"strict":true},
      name: "finance-portfolio"
    }, {
      path: ":portfolio_id/:tab_id?/",
      component: _5fb91434,
      pathToRegexpOptions: {"strict":true},
      name: "finance-portfolio-portfolio_id-tab_id"
    }]
  }, {
    path: "/en/sandbox/portfolio/",
    component: _764d410f,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/en",
      component: _6ae28052,
      pathToRegexpOptions: {"strict":true},
      name: "sandbox-portfolio"
    }, {
      path: ":portfolio_id/:tab_id?/",
      component: _5fb91434,
      pathToRegexpOptions: {"strict":true},
      name: "sandbox-portfolio-portfolio_id-tab_id"
    }]
  }, {
    path: "/es/finanzas/portafolio/",
    component: _764d410f,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/es",
      component: _6ae28052,
      pathToRegexpOptions: {"strict":true},
      name: "finanzas-portafolio"
    }, {
      path: ":portfolio_id/:tab_id?/",
      component: _5fb91434,
      pathToRegexpOptions: {"strict":true},
      name: "finanzas-portafolio-portfolio_id-tab_id"
    }]
  }, {
    path: "/es/salvadera/portafolio/",
    component: _764d410f,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/es",
      component: _6ae28052,
      pathToRegexpOptions: {"strict":true},
      name: "salvadera-portafolio"
    }, {
      path: ":portfolio_id/:tab_id?/",
      component: _5fb91434,
      pathToRegexpOptions: {"strict":true},
      name: "salvadera-portafolio-portfolio_id-tab_id"
    }]
  }, {
    path: "/fr/finance/portefeuille/",
    component: _764d410f,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/fr",
      component: _6ae28052,
      pathToRegexpOptions: {"strict":true},
      name: "finance-portefeuille"
    }, {
      path: ":portfolio_id/:tab_id?/",
      component: _5fb91434,
      pathToRegexpOptions: {"strict":true},
      name: "finance-portefeuille-portfolio_id-tab_id"
    }]
  }, {
    path: "/fr/virtuel/portefeuille/",
    component: _764d410f,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/fr",
      component: _6ae28052,
      pathToRegexpOptions: {"strict":true},
      name: "virtuel-portefeuille"
    }, {
      path: ":portfolio_id/:tab_id?/",
      component: _5fb91434,
      pathToRegexpOptions: {"strict":true},
      name: "virtuel-portefeuille-portfolio_id-tab_id"
    }]
  }, {
    path: "/de/registrieren/erfolg/",
    component: _eb0b3300,
    pathToRegexpOptions: {"strict":true},
    name: "registrieren-erfolg"
  }, {
    path: "/en/signup/success/",
    component: _eb0b3300,
    pathToRegexpOptions: {"strict":true},
    name: "signup-success"
  }, {
    path: "/es/registrarse/exito/",
    component: _eb0b3300,
    pathToRegexpOptions: {"strict":true},
    name: "registrarse-exito"
  }, {
    path: "/fr/inscription/succes/",
    component: _eb0b3300,
    pathToRegexpOptions: {"strict":true},
    name: "inscription-succes"
  }, {
    path: "/de/kasse/erstellen/:product_id?/",
    component: _16038c9f,
    pathToRegexpOptions: {"strict":true},
    name: "kasse-erstellen-product_id"
  }, {
    path: "/en/checkout/create/:product_id?/",
    component: _16038c9f,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-create-product_id"
  }, {
    path: "/es/caja/crear/:product_id?/",
    component: _16038c9f,
    pathToRegexpOptions: {"strict":true},
    name: "caja-crear-product_id"
  }, {
    path: "/fr/caisse/creer/:product_id?/",
    component: _16038c9f,
    pathToRegexpOptions: {"strict":true},
    name: "caisse-creer-product_id"
  }, {
    path: "/de/marktanalyse/:tab_id?/",
    component: _3f6e54c4,
    pathToRegexpOptions: {"strict":true},
    name: "market-analysis-tab_id"
  }, {
    path: "/en/market-analysis/:tab_id?/",
    component: _3f6e54c4,
    pathToRegexpOptions: {"strict":true},
    name: "market-analysis-tab_id"
  }, {
    path: "/es/analisis-de-mercado/:tab_id?/",
    component: _3f6e54c4,
    pathToRegexpOptions: {"strict":true},
    name: "market-analysis-tab_id"
  }, {
    path: "/fr/analyse-de-marche/:tab_id?/",
    component: _3f6e54c4,
    pathToRegexpOptions: {"strict":true},
    name: "market-analysis-tab_id"
  }, {
    path: "/de/",
    component: _1439d70e,
    pathToRegexpOptions: {"strict":true},
    name: "de"
  }, {
    path: "/en/",
    component: _1439d70e,
    pathToRegexpOptions: {"strict":true},
    name: "en"
  }, {
    path: "/es/",
    component: _1439d70e,
    pathToRegexpOptions: {"strict":true},
    name: "es"
  }, {
    path: "/fr/",
    component: _1439d70e,
    pathToRegexpOptions: {"strict":true},
    name: "fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
