import routingConfig from "../config/routing.config";
import _ from 'lodash';

const { urlPathSegments, originalPublicRoutePaths, tabIds } = routingConfig;

/**
 * Get translated route name from original route name
 * @param {*} originalName e.g. "finance-dashboard"
 * @param {*} lang "de"
 * @returns "finanzen-uebersicht"
 */
const getTranslatedRouteName = (originalName, lang) => {
    return originalName.split('-').map(segment =>
        urlPathSegments[lang][segment] || segment
    ).join('-');
}

/**
 * Get translated route path from original route path
 * @param {*} originalName e.g. "/finance/dashboard"
 * @param {*} lang "de"
 * @returns "finanzen-uebersicht"
 */
const getTranslatedRoutePath = (originalPath, lang) => {
    if (originalPath === '/signup/' && lang === 'en') {
        return '/signup/';
    }

    const path = originalPath.split('/').map(segment => {
        if (segment.startsWith(':')) return segment;
        return urlPathSegments[lang][segment] || segment;
    }).join('/');

    return originalPath.startsWith(':') ? path : `/${lang}${path}`
}

const translateRoute = (route, lang) => {
    const translatedPath = getTranslatedRoutePath(route.path, lang)

    const translatedRoute = {
        ...route,
        path: translatedPath
    };

    if (route.name) {
        translatedRoute.name = getTranslatedRouteName(route.name, lang)
    }

    if (route.children) {
        translatedRoute.children = route.children.map(child => translateRoute(child, lang));
    }

    return translatedRoute;
}

const isPublicRoute = (route, locale) => {
    const indexPageRouteName = getTranslatedRouteName('index', locale)
    if (route?.name === indexPageRouteName) {
        return true
    }

    if (route?.fullPath === getTranslatedRoutePath('/market-analysis/personalized/', locale)){
        return false
    }

    const translatedPublicPaths = originalPublicRoutePaths.map(path => getTranslatedRoutePath(`/${path}/`, locale))    
    return translatedPublicPaths.some(path => route?.fullPath?.startsWith(path))
}

/**
 * Get original route path from current route path
 * @param {*} currentRouteName e.g. "/de/finanzen/uebersicht/"
 * @param {*} currentLocale e.g. "de"
 * @returns e.g. "/finance/dashboard/"
 */
const getOriginalRoutePath = (currentRoutePath, currentLocale) => {
    const revertedUrlPathSegments = _.invert(urlPathSegments[currentLocale])
    currentRoutePath = currentRoutePath.startsWith(':') ? currentRoutePath : currentRoutePath.substring(3)

    return currentRoutePath.split('/').map(segment => {
        if (segment.startsWith(':')) return segment;
        return revertedUrlPathSegments[segment] || segment;
    }).join('/');
}

/**
 * Get origianl tab id
 * @param {*} currentTabId e.g. "aktien"
 * @param {*} currentLocale e.g. "de"
 * @returns e.g. "stocks"
 */
const getOriginalTabId = (currentTabId, currentLocale) => {
    const revertedTabIds = _.invert(tabIds[currentLocale])
    return revertedTabIds[currentTabId]
}

/**
 * Get translated tab id
 * @param {*} originalTabId  e.g. "stocks"
 * @param {*} locale e.g. "de"
 * @returns "aktien"
 */
const getTranslatedTabId = (originalTabId, locale) => {
    return tabIds[locale][originalTabId]
}

/**
 * Direct from current URL to translated URL based on user selected locale
 * e.g. from "/en/finance/dashboard/stocks/" to /de/finanzen/uebersicht/aktien/
 */
const directToTranslatedRoute = ({ route, router, currentLocale, newLocale }) => {
    const originalRoutePath = getOriginalRoutePath(route.fullPath, currentLocale)
    const translatedRoutePath = getTranslatedRoutePath(originalRoutePath, newLocale)

    router.replace({  
        path: translatedRoutePath,
    })
}

const getTranslatedRouteSegment = (originalKey, locale) => {
    return urlPathSegments[locale][originalKey] ?? originalKey
}

const shouldBeSandboxRoute = (routeName, routePath) => {
    if (routeName) {
        if (routeName.startsWith('finance-') || routeName.startsWith('aktie-')) {
            return true;
        }
    }
    return routePath.includes('/finance/') || routePath.includes('/aktie/');
}

function updateRouteName(routeName) {
    if (!routeName) {
        return undefined;
    }
    if (routeName.startsWith('finance-')) {
        return routeName.replace('finance-', 'sandbox-');
    }
    if (routeName.startsWith('aktie-')) {
        return 'sandbox-' + routeName;
    }
    return routeName;
}

function updateRoutePath(routePath) {
    if (routePath.includes('/finance/')) {
        return routePath.replace('/finance/', '/sandbox/');
    }
    if (routePath.includes('/aktie/')) {
        return routePath.replace('/aktie/', '/sandbox/aktie/');
    }
    return routePath;
}

function updateChunkName(chunkName) {
    if (chunkName && chunkName.includes('finance')) {
        return chunkName.replace('finance', 'sandbox');
    }
    return chunkName;
}

function getSandboxRoute(route) {
    const newRoute = JSON.parse(JSON.stringify(route));

    newRoute.name = updateRouteName(newRoute.name);
    newRoute.path = updateRoutePath(newRoute.path);
    newRoute.chunkName = updateChunkName(newRoute.chunkName);

    if (newRoute.children && newRoute.children.length > 0) {
        newRoute.children = newRoute.children.map(child => getSandboxRoute(child));
    }

    return newRoute;
}

export default {
    translateRoute,
    getTranslatedRouteName,
    getTranslatedRoutePath,
    directToTranslatedRoute,
    getOriginalTabId,
    getTranslatedTabId,
    getOriginalRoutePath,
    isPublicRoute,
    shouldBeSandboxRoute,
    getSandboxRoute,
    getTranslatedRouteSegment,
}
