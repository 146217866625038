const tabIdsEn = {
    stocks: 'stocks',
    transactions: 'transactions',
    realized: 'realized',
    dividends: 'dividends',
    performance: 'performance',
    allocation: 'allocation',
    kennzahlen: 'key-figures',
    'gewinn-und-verlust': 'profit-and-loss',
    bilanz: 'balance-sheet',
    cashflow: 'cashflow',
    ergebnis: 'earnings',
    personalized: 'personalized',
    sentiment: 'sentiment',
}

const tabIdsDe = {
    stocks: 'aktien',
    transactions: 'transaktionen',
    realized: 'realisierte',
    dividends: 'dividenden',
    performance: 'performance',
    allocation: 'zuteilung',
    kennzahlen: 'kennzahlen',
    'gewinn-und-verlust': 'gewinn-und-verlust',
    bilanz: 'bilanz',
    cashflow: 'cashflow',
    ergebnis: 'ergebnis',
    personalized: 'personalisiert',
    sentiment: 'marktstimmung',
}

const tabIdsEs = {
    stocks: 'acciones',
    transactions: 'transacciones',
    realized: 'realizadas',
    dividends: 'dividendos',
    performance: 'rendimiento',
    allocation: 'asignación',
    kennzahlen: 'indicadores-clave',
    'gewinn-und-verlust': 'ganancias-y-perdidas',
    bilanz: 'balance',
    cashflow: 'flujo-de-caja',
    ergebnis: 'resultados',
    personalized: 'personalizado',
    sentiment: 'sentimiento',
}

const tabIdsFr = {
    stocks: 'actions',
    transactions: 'transactions',
    realized: 'realisees',
    dividends: 'dividendes',
    performance: 'performance',
    allocation: 'allocation',
    kennzahlen: 'indicateurs-cles',
    'gewinn-und-verlust': 'gains-et-pertes',
    bilanz: 'bilan',
    cashflow: 'flux-de-trésorerie',
    ergebnis: 'résultats',
    personalized: 'personnalise',
    sentiment: 'sentiment',
}

// NOTE: DO NOT let any urlPathSegments object contain keys that have same value
const urlPathSegmentsEn = {
    aktie: 'stock',
    share: 'share',
    fundamentaldaten: 'fundamentaldata',
    checkout: 'checkout',
    create: 'create',
    success: 'success',
    feature: 'feature',
    individuelle: 'individual',
    assets: 'assets',
    'individuelle-assets': 'individual-assets',
    finance: 'finance',
    dashboard: 'dashboard',
    portfolio: 'portfolio',
    signup: 'signup',
    automatisch: 'auto',
    importieren: 'import',
    'feature-portfolio-automatisch-importieren': 'feature-portfolio-auto-import',
    feedback: 'feedback',
    maintenance: 'maintenance',
    user: 'user',
    settings: 'settings',
    profile: 'profile',
    index: 'en',
    'market-analysis': 'market-analysis',
    login: 'login',
    sandbox: 'sandbox',
    ...tabIdsEn
};

const urlPathSegmentsDe = {
    aktie: 'aktie',
    share: 'share',
    fundamentaldaten: 'fundamentaldaten',
    checkout: 'kasse',
    create: 'erstellen',
    success: 'erfolg',
    feature: 'feature',
    individuelle: 'individuelle',
    assets: 'assets',
    'individuelle-assets': 'individuelle-assets',
    finance: 'finanzen',
    dashboard: 'uebersicht',
    portfolio: 'portfolio',
    signup: 'registrieren',
    automatisch: 'automatisch',
    importieren: 'importieren',
    'feature-portfolio-automatisch-importieren': 'feature-portfolio-automatisch-importieren',
    feedback: 'feedback',
    maintenance: 'wartung',
    user: 'benutzer',
    settings: 'einstellungen',
    profile: 'profil',
    index: 'de',
    'market-analysis': 'marktanalyse',
    login: 'anmelden',
    sandbox: 'virtual',
    ...tabIdsDe
}

const urlPathSegmentsEs = {
    aktie: 'compartir',
    share: 'accion',
    fundamentaldaten: 'datos-fundamentales',
    checkout: 'caja',
    create: 'crear',
    success: 'exito',
    feature: 'característica',
    individuelle: 'individual',
    assets: 'activos',
    'individuelle-assets': 'activos-individuales',
    finance: 'finanzas',
    dashboard: 'tablero',
    portfolio: 'portafolio',
    signup: 'registrarse',
    automatisch: 'automático',
    importieren: 'importar',
    'feature-portfolio-automatisch-importieren': 'importacion-automatica-de-cartera-de-caracteristicas',
    feedback: 'retroalimentacion',
    maintenance: 'mantenimiento',
    user: 'usuario',
    settings: 'configuracion',
    profile: 'perfil',
    index: 'es',
    'market-analysis': 'analisis-de-mercado',
    login: 'acceso',
    sandbox: 'salvadera',
    ...tabIdsEs
};

const urlPathSegmentsFr = {
    aktie: 'partager',
    share: 'action',
    fundamentaldaten: 'donnees-fondamentales',
    checkout: 'caisse',
    create: 'creer',
    success: 'succes',
    feature: 'fonctionnalite',
    individuelle: 'individuel',
    assets: 'actifs',
    'individuelle-assets': 'actifs-individuels',
    finance: 'finance',
    dashboard: 'tableau-de-bord',
    portfolio: 'portefeuille',
    signup: 'inscription',
    automatisch: 'automatique',
    importieren: 'importer',
    'feature-portfolio-automatisch-importieren': 'importation-automatique-du-portefeuille-de-fonctionnalités',
    feedback: 'retour',
    maintenance: 'maintenance',
    user: 'utilisateur',
    settings: 'parametres',
    profile: 'profil',
    index: 'fr',
    'market-analysis': 'analyse-de-marche',
    login: 'se-connecter',
    sandbox: 'virtuel',
    ...tabIdsFr,
};

const urlPathSegments = {
    en: urlPathSegmentsEn,
    de: urlPathSegmentsDe,
    es: urlPathSegmentsEs,
    fr: urlPathSegmentsFr
};

const tabIds = {
    en: tabIdsEn,
    de: tabIdsDe,
    es: tabIdsEs,
    fr: tabIdsFr
};

const originalPublicRoutePaths = [
    'user-mgmt',
    'maintenance',
    'signup',
    'signup/success',
    'aktie',
    'market-analysis',
    'login',
];

export default {
    urlPathSegments,
    originalPublicRoutePaths,
    tabIds
}