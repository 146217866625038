import theme from '@/assets/theme.scss';
import User from '@/models/User';
import AuthService from '@/service/AuthService';
import routingUtils from "@/utils/routing.utils";
import { createChart } from 'lightweight-charts';
import CdnService from "./service/CdnService";

const { isPublicRoute } = routingUtils

export const backendExceptionHandler = {
    methods: {
        /**
         * @param Error exception
         * @returns string A description of the error that exists after handling the exception, or null
         */
        handleBackendException: function(exception, errorContext = 'Backend-Fehler: ', updateMessageEvent = '') {
            if (exception.code == 'ECONNABORTED') {
                this.errorMessage = null
                return
            }
            if (exception.message === 'Network Error') {
                this.$root.$emit('network-error')
            }
            const indexPageRouteName = this.$getTranslatedRouteName('index')
            if (exception.code === 'ECONNABORTED' || exception.message === 'Network Error'
                    || this.$route?.name == indexPageRouteName && exception.response?.status == 401) {
                this.errorMessage = null
                return
            }
            if (!exception.response && exception.message) {
                this.errorMessage = exception.message
                return
            }
            if (exception?.response?.status === 404) {
                this.$nuxt.context.error({
                    statusCode: 404,
                    message: 'Wir haben nicht gefunden wonach du suchst',
                })
    
                return
            }

            let responseError = errorContext
            if (exception.response?.data.message || exception.response?.data.error) {
                responseError += ': ' + (exception.response?.data.message ?? exception.response?.data.error ?? '').toString()
            }
            this.$gtag?.exception({ description: responseError })
            if (exception?.response?.status === 426) {
                window.location.reload()
                return
            }
            if (exception.response?.status != 503) {
                if (updateMessageEvent) {
                    this.$emit(updateMessageEvent, responseError);
                }
                this.errorMessage = responseError
                return
            }
            const maintenanceRouteName = this.$getTranslatedRouteName('maintenance')
            this.$router.push({ name: maintenanceRouteName, params: { originator: window.location.pathname, message: exception.response.data.message ?? null}})
            this.errorMessage = null
        },
    }
}

export const paginationStateHandler = {
    methods: {
        onPagination(e) {
            if (e.itemsPerPage !== this.itemsPerPage) {
                this.$store.commit('preferences/pageSizeSelection', {
                    name: this.$options.name, size: e.itemsPerPage
                })
            }
        }
    },

    created() {
        if (!this.itemsPerPage) {
            this.itemsPerPage = 100
        }
        this.itemsPerPage = this.$store.getters['preferences/getPageSize'](this.$options.name)
            || this.itemsPerPage
    },
}

export const sortHandler = {
    methods: {
        handleSortBy(){
            this.$nextTick(() => {
                this.sortDesc = true
            })
        }

    }
}

export const authStateHandler = {
    methods: {
        authStateChanged: function(user) {
            this.authInitialized = true
            if (user) {
                const providerId = user.providerData[0]?.providerId
                const preMessage = `Um Dich über ${providerId} anzumelden benötigst du`
                if (!user.email) {
                    this.$root.$emit(
                        'login-rejected',
                        `${preMessage} eine gültige eMail-Adresse`,
                    )
                } else if (user.providerData[0]?.providerId == 'password' && !user.emailVerified) {
                    this.$root.$emit(
                        'login-rejected',
                        `${preMessage} eine verifizierte eMail-Adresse`,
                    )
                } else {
                    this.$store.commit('account/login', new User(
                        user.uid,
                        user.email,
                    ))
                    try {
                        AuthService.ping(this.$axios, this.$t)
                    } catch (e) {
                        console.log(e)
                    }
                    return
                }
            }
            if (this.$store.state.account.user?.preventLogout) {
                return  // workaround for e2e testing until we use Firebase emulator
            }
            this.$store.commit('account/setIsTwoFaEnabled', false)
            this.$store.commit('account/logout')
            this.$store.commit('chart/setBenchmarks', [])
            this.$store.commit('chart/setMarketAnalysisBenchmarks', [])
            const indexPageRouteName = this.$getTranslatedRouteName('index')
            //if (this.$route.matched.some(matched => matched.beforeEnter)) {
            if (this.$route && !isPublicRoute(this.$route, this.$getCurrentLocale())) {
                // if it's an auth-protected page, redirect to login page
                this.$router.push({
                    name: indexPageRouteName,
                    params: { redirectMessage: 'Du wurdest ausgeloggt' },
                })
            }
        }
    }
}

export const chartIntervalStateHandler = {
    methods: {
        changeInterval(interval) {
            this.$store.commit('preferences/activeChartInterval', interval)
        }
    },

    created() {
        if (!this.activeInterval) {
            this.activeInterval = 'day'
        } else {
            this.activeInterval = this.$store.getters['preferences/activeChartInterval']
        }
    },
}

export const companyLogoHandler = {
    methods: {
        replaceByDefaultLogo(e) {
            const element = e.target
            element.parentElement.classList.remove("company-logo")
            element.parentElement.classList.add("company-logo-invalid")
            e.target.src = require('@/assets/spatz-default.png')
        },

        getCompanyLogoUrl(logo) {
            if(!logo) return require('@/assets/spatz-default.png')
            return this.cdnService.getLogoUrl(logo);
        },

        getStockLogoClass(logo) {
            return (logo && logo != null) ? 'company-logo' : 'company-logo-invalid'
        },

        getStockLogoUrl(logo) {
            return this.cdnService.getStockLogoUrl(logo);
        },
        onLoadedLogo(e) {
            const element = e.target
            // remove invalid logo class
            if (!element.src.includes('assets/spatz-default.png')) {
                element.parentElement.classList.remove("company-logo-invalid")
                element.parentElement.classList.add("company-logo")
            }
        },

        getAssetMethodLogoUrl(logoName) {
            return require(`@/static/svg/asset_portfolio_types/${logoName}`)
        },
    },

    created() {
        this.cdnService = new CdnService()
    },
}

export const dividendYearSelectedStateHandler = {
    methods: {
        changeDividendYearSelected(year) {
            this.$store.commit('preferences/setDividendYearSelected', year)
        }
    }
}

export const lightweightChartMixin = {
    computed: {
        colors() {
            return [
                theme['chart-graph-color-1'],
                theme['chart-graph-color-2'],
                theme['chart-graph-color-3'],
                theme['chart-graph-color-4'],
                theme['chart-graph-color-5'],
                theme['chart-graph-color-6'],
                theme['chart-graph-color-7'],
                theme['chart-graph-color-8'],
                theme['chart-graph-color-9'],
                theme['chart-graph-color-10'],
                theme['chart-graph-color-11'],
                theme['chart-graph-color-12'],
                theme['chart-graph-color-13'],
                theme['chart-graph-color-14'],
                theme['chart-graph-color-15'],
                theme['chart-graph-color-16'],
            ]
        },
        isDarkMode() {
            return this.$vuetify.theme.dark
        },
        chartStyleLight() {
            return {
                layout: {
                    background: { color: 'transparent' },
                    textColor: theme['text-primary-light'],
                },
                timeScale: {
                    borderColor: theme['border-secondary-light'],
                },
                rightPriceScale: {
                    borderColor: theme['border-secondary-light'],
                },
                grid: {
                    vertLines: {
                        color: theme['border-secondary-light'],
                        style: 2,
                    },
                    horzLines: {
                        color: theme['border-secondary-light'],
                        style: 2,
                    },
                },
            }
        },
        chartStyleDark() {
            return {
                layout: {
                    background: { color: 'transparent' },
                    textColor: theme['text-primary-dark'],
                },
                timeScale: {
                    borderColor: theme['border-secondary-dark'],
                },
                rightPriceScale: {
                    borderColor: theme['border-secondary-dark'],
                },
                grid: {
                    vertLines: {
                        color: theme['border-secondary-dark'],
                        style: 2,
                    },
                    horzLines: {
                        color: theme['border-secondary-dark'],
                        style: 2,
                    },
                },
            }
        },
    },
    watch: {
        isDarkMode: {
            immediate: true,
            handler(newState) {
                if (this.chart) {
                    this.chart.applyOptions(
                        newState ? this.chartStyleDark : this.chartStyleLight
                    )
                }
            }
        },
    },

    mounted() {
        window.addEventListener('resize', this.setChartWidth)
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.setChartWidth)
    },

    methods: {
        createChart(config) {
            const chartOptions = {
                handleScale: false,
                handleScroll: false,
                ...(this.isDarkMode ? this.chartStyleDark : this.chartStyleLight),
                ...config
            }
            const chart = createChart(this.$refs.chart, chartOptions);
            this.setChartWidth()
            chart.timeScale().fitContent()
            this.chart = chart
        },
        setChartWidth() {
            if (this.chart && this.$refs.chartWrapper) {
                this.chart.applyOptions({
                    width: this.$refs.chartWrapper.clientWidth,
                })
                this.chart.timeScale().fitContent()
            }
        },
        getTimezoneCorrectedTime(utcTime, returnAsUnixTimestamp = false) {
            if(isNaN(utcTime)) utcTime = new Date(utcTime)
            if(utcTime instanceof Date) utcTime = utcTime.getTime()/1000

            const timezoneOffsetMinutes = new Date().getTimezoneOffset()
            const correctedTime = utcTime+(-timezoneOffsetMinutes*60)

            if(returnAsUnixTimestamp) return correctedTime
            return new Date(correctedTime*1000)
        },
    },
}
