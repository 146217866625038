

export default {
    name: 'PrimaryButton',

    props: {
        text: {
            type: String,
            default: ''
        },
        width: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
}

