
import PrimaryButton from '@/components/shared/PrimaryButton';

export default {
    name: 'IntroductionSandboxModal',
    components: {
        PrimaryButton,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        closeModal() {
            this.$emit('input', false);
        },
        onDialogInput(event) {
            this.$emit('input', event);
        },
    }
}
