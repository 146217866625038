import localizationConfig from "../config/localization.config";

const { FALLBACK_LOCALE, AVAILABLE_LOCALE_CODES } = localizationConfig;

const detectBrowserLanguage = (req) => {
    if (process.server && req && req.headers['accept-language']) {
        return req.headers['accept-language'].split(',')[0].toLocaleLowerCase().substring(0, 2);
    }

    if (process.client) {
        const language = navigator.languages && navigator.languages.length
            ? navigator.languages[0]
            : navigator.language;

        return language ? language.substring(0, 2) : FALLBACK_LOCALE;
    }

    return FALLBACK_LOCALE;
};

const getCurrentLocale = (store, route) => {
    const isLoggedIn = !!store.state.account.user
    const routeLang = route.path.split('/')?.[1];
    const shouldUseRouteLang = !isLoggedIn && AVAILABLE_LOCALE_CODES.includes(routeLang)
    const language = shouldUseRouteLang ?
        routeLang :
        (store.getters['preferences/getLocale'] || detectBrowserLanguage());
    return AVAILABLE_LOCALE_CODES.includes(language) ? language : FALLBACK_LOCALE;
};

export default {
    detectBrowserLanguage,
    getCurrentLocale
}