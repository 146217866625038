const state = () => ({
    benchmarks: [],
    marketAnalysisBenchmarks: [],
});

const mutations = {
    setBenchmarks(state, payload) {
        state.benchmarks = payload
    },
    setMarketAnalysisBenchmarks(state, payload) {
        state.marketAnalysisBenchmarks = payload
    },
};

const getters = {
    getBenchmarks: (state) => {
        return state.benchmarks
    },
    getMarketAnalysisBenchmarks: (state) => {
        return state.marketAnalysisBenchmarks
    },
};

export default {
    state,
    mutations,
    getters
}
