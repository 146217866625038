import Vue from 'vue';
import routingUtils from '~/utils/routing.utils';
import localizationUtils from '~/utils/localization.utils';

const { getCurrentLocale } = localizationUtils

const {
    getTranslatedRouteName: getTranslatedRouteNameUtil,
    getTranslatedRoutePath,
    getTranslatedTabId,
    getOriginalTabId,
    getOriginalRoutePath,
    getTranslatedRouteSegment,
} = routingUtils;

const isSandboxMode = (route, locale) => {
    const sandBoxPath = getTranslatedRouteSegment('sandbox', locale)
    return route.path.includes(`/${sandBoxPath}/`)
}

const getTranslatedRouteName = (context, originalName, locale) => {
    const localeToTranslate = locale ?? getCurrentLocale(context.store, context.route);
    const isSandbox = isSandboxMode(context.route, localeToTranslate)

    let modifiedName = originalName;
    if (isSandbox) {
        if (originalName.startsWith('finance-')) {
            modifiedName = originalName.replace('finance-', 'sandbox-');
        } else if (originalName.startsWith('aktie-')) {
            modifiedName = 'sandbox-' + originalName;
        }
    }

    return getTranslatedRouteNameUtil(modifiedName, localeToTranslate);
};

export default (context) => {
    Vue.prototype.$getTranslatedRouteName = (originalName, locale) =>
        getTranslatedRouteName(context, originalName, locale);
    Vue.prototype.$getTranslatedRoutePath = (originalPath, locale) =>
        getTranslatedRoutePath(originalPath, locale ?? getCurrentLocale(context.store, context.route));
    Vue.prototype.$getOriginalRoutePath = (path, locale) =>
        getOriginalRoutePath(path, locale ?? getCurrentLocale(context.store, context.route));
    Vue.prototype.$getTranslatedTabId = (originalTabId, locale) =>
        getTranslatedTabId(originalTabId, locale ?? getCurrentLocale(context.store, context.route));
    Vue.prototype.$getOriginalTabId = (tabId, locale) =>
        getOriginalTabId(tabId, locale ?? getCurrentLocale(context.store, context.route));
    Vue.prototype.$isSandboxMode = (route, locale) =>
        isSandboxMode(route ?? context.route, locale ?? getCurrentLocale(context.store, context.route));
};
