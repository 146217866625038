export const Alert = () => import('../../components/Alert.vue' /* webpackChunkName: "components/alert" */).then(c => wrapFunctional(c.default || c))
export const Brand = () => import('../../components/Brand.vue' /* webpackChunkName: "components/brand" */).then(c => wrapFunctional(c.default || c))
export const HelpCenter = () => import('../../components/HelpCenter.vue' /* webpackChunkName: "components/help-center" */).then(c => wrapFunctional(c.default || c))
export const LabeledTextField = () => import('../../components/LabeledTextField.vue' /* webpackChunkName: "components/labeled-text-field" */).then(c => wrapFunctional(c.default || c))
export const LoadingIndicator = () => import('../../components/LoadingIndicator.vue' /* webpackChunkName: "components/loading-indicator" */).then(c => wrapFunctional(c.default || c))
export const LocaleSelector = () => import('../../components/LocaleSelector.vue' /* webpackChunkName: "components/locale-selector" */).then(c => wrapFunctional(c.default || c))
export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const OpenPopupTooltip = () => import('../../components/OpenPopupTooltip.vue' /* webpackChunkName: "components/open-popup-tooltip" */).then(c => wrapFunctional(c.default || c))
export const Page = () => import('../../components/Page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const PortfolioKpiHolisticPerformanceIndex = () => import('../../components/PortfolioKpiHolisticPerformanceIndex.vue' /* webpackChunkName: "components/portfolio-kpi-holistic-performance-index" */).then(c => wrapFunctional(c.default || c))
export const PortfolioKpiReturnValue = () => import('../../components/PortfolioKpiReturnValue.vue' /* webpackChunkName: "components/portfolio-kpi-return-value" */).then(c => wrapFunctional(c.default || c))
export const PortfolioKpiStartValueIsRoundedExplain = () => import('../../components/PortfolioKpiStartValueIsRoundedExplain.vue' /* webpackChunkName: "components/portfolio-kpi-start-value-is-rounded-explain" */).then(c => wrapFunctional(c.default || c))
export const PortfolioKpiValueChange = () => import('../../components/PortfolioKpiValueChange.vue' /* webpackChunkName: "components/portfolio-kpi-value-change" */).then(c => wrapFunctional(c.default || c))
export const PortfolioKpiValueIsRoundedExplain = () => import('../../components/PortfolioKpiValueIsRoundedExplain.vue' /* webpackChunkName: "components/portfolio-kpi-value-is-rounded-explain" */).then(c => wrapFunctional(c.default || c))
export const Searchbar = () => import('../../components/Searchbar.vue' /* webpackChunkName: "components/searchbar" */).then(c => wrapFunctional(c.default || c))
export const SiteFooter = () => import('../../components/SiteFooter.vue' /* webpackChunkName: "components/site-footer" */).then(c => wrapFunctional(c.default || c))
export const SiteHeader = () => import('../../components/SiteHeader.vue' /* webpackChunkName: "components/site-header" */).then(c => wrapFunctional(c.default || c))
export const UserMessage = () => import('../../components/UserMessage.vue' /* webpackChunkName: "components/user-message" */).then(c => wrapFunctional(c.default || c))
export const UserMessageDialog = () => import('../../components/UserMessageDialog.vue' /* webpackChunkName: "components/user-message-dialog" */).then(c => wrapFunctional(c.default || c))
export const UserMessages = () => import('../../components/UserMessages.vue' /* webpackChunkName: "components/user-messages" */).then(c => wrapFunctional(c.default || c))
export const VuetifyLogo = () => import('../../components/VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => wrapFunctional(c.default || c))
export const AuthChangeEmail = () => import('../../components/auth/ChangeEmail.vue' /* webpackChunkName: "components/auth-change-email" */).then(c => wrapFunctional(c.default || c))
export const AuthChangePassword = () => import('../../components/auth/ChangePassword.vue' /* webpackChunkName: "components/auth-change-password" */).then(c => wrapFunctional(c.default || c))
export const AuthDeleteAccount = () => import('../../components/auth/DeleteAccount.vue' /* webpackChunkName: "components/auth-delete-account" */).then(c => wrapFunctional(c.default || c))
export const AuthDisableTwoFactorAuthentication = () => import('../../components/auth/DisableTwoFactorAuthentication.vue' /* webpackChunkName: "components/auth-disable-two-factor-authentication" */).then(c => wrapFunctional(c.default || c))
export const AuthEnableTwoFactorAuthentication = () => import('../../components/auth/EnableTwoFactorAuthentication.vue' /* webpackChunkName: "components/auth-enable-two-factor-authentication" */).then(c => wrapFunctional(c.default || c))
export const AuthMenu = () => import('../../components/auth/Menu.vue' /* webpackChunkName: "components/auth-menu" */).then(c => wrapFunctional(c.default || c))
export const ChartAreaChart = () => import('../../components/chart/AreaChart.vue' /* webpackChunkName: "components/chart-area-chart" */).then(c => wrapFunctional(c.default || c))
export const ChartBarChart = () => import('../../components/chart/BarChart.vue' /* webpackChunkName: "components/chart-bar-chart" */).then(c => wrapFunctional(c.default || c))
export const ChartPieChart = () => import('../../components/chart/PieChart.vue' /* webpackChunkName: "components/chart-pie-chart" */).then(c => wrapFunctional(c.default || c))
export const DividendsBookDividends = () => import('../../components/dividends/BookDividends.vue' /* webpackChunkName: "components/dividends-book-dividends" */).then(c => wrapFunctional(c.default || c))
export const DividendsDividendDelete = () => import('../../components/dividends/DividendDelete.vue' /* webpackChunkName: "components/dividends-dividend-delete" */).then(c => wrapFunctional(c.default || c))
export const DividendsDividendEditAndCreate = () => import('../../components/dividends/DividendEditAndCreate.vue' /* webpackChunkName: "components/dividends-dividend-edit-and-create" */).then(c => wrapFunctional(c.default || c))
export const DividendsDividendFirstPositionInfo = () => import('../../components/dividends/DividendFirstPositionInfo.vue' /* webpackChunkName: "components/dividends-dividend-first-position-info" */).then(c => wrapFunctional(c.default || c))
export const DividendsList = () => import('../../components/dividends/DividendsList.vue' /* webpackChunkName: "components/dividends-list" */).then(c => wrapFunctional(c.default || c))
export const HeaderMobileMenu = () => import('../../components/header/MobileMenu.vue' /* webpackChunkName: "components/header-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const HeaderPageNavigation = () => import('../../components/header/PageNavigation.vue' /* webpackChunkName: "components/header-page-navigation" */).then(c => wrapFunctional(c.default || c))
export const HelpcenterDashboardHelpCenterModal = () => import('../../components/helpcenter/DashboardHelpCenterModal.vue' /* webpackChunkName: "components/helpcenter-dashboard-help-center-modal" */).then(c => wrapFunctional(c.default || c))
export const HelpcenterDividendenHelpCenterModal = () => import('../../components/helpcenter/DividendenHelpCenterModal.vue' /* webpackChunkName: "components/helpcenter-dividenden-help-center-modal" */).then(c => wrapFunctional(c.default || c))
export const HelpcenterKPILeisteHelpCenterModal = () => import('../../components/helpcenter/KPILeisteHelpCenterModal.vue' /* webpackChunkName: "components/helpcenter-k-p-i-leiste-help-center-modal" */).then(c => wrapFunctional(c.default || c))
export const HelpcenterMeinPortfolioHelpCenterModal = () => import('../../components/helpcenter/MeinPortfolioHelpCenterModal.vue' /* webpackChunkName: "components/helpcenter-mein-portfolio-help-center-modal" */).then(c => wrapFunctional(c.default || c))
export const HelpcenterPortfolioTeilenHelpCenterModal = () => import('../../components/helpcenter/PortfolioTeilenHelpCenterModal.vue' /* webpackChunkName: "components/helpcenter-portfolio-teilen-help-center-modal" */).then(c => wrapFunctional(c.default || c))
export const HelpcenterPositionenImportierenHelpCenterModal = () => import('../../components/helpcenter/PositionenImportierenHelpCenterModal.vue' /* webpackChunkName: "components/helpcenter-positionen-importieren-help-center-modal" */).then(c => wrapFunctional(c.default || c))
export const HelpcenterWerteVergleichenHelpCenterModal = () => import('../../components/helpcenter/WerteVergleichenHelpCenterModal.vue' /* webpackChunkName: "components/helpcenter-werte-vergleichen-help-center-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketAnalysisBullBearRatioChart = () => import('../../components/marketAnalysis/BullBearRatioChart.vue' /* webpackChunkName: "components/market-analysis-bull-bear-ratio-chart" */).then(c => wrapFunctional(c.default || c))
export const MarketAnalysisFearAndGreedIndex = () => import('../../components/marketAnalysis/FearAndGreedIndex.vue' /* webpackChunkName: "components/market-analysis-fear-and-greed-index" */).then(c => wrapFunctional(c.default || c))
export const MarketAnalysisInterestRateChart = () => import('../../components/marketAnalysis/InterestRateChart.vue' /* webpackChunkName: "components/market-analysis-interest-rate-chart" */).then(c => wrapFunctional(c.default || c))
export const MarketAnalysisChart = () => import('../../components/marketAnalysis/MarketAnalysisChart.vue' /* webpackChunkName: "components/market-analysis-chart" */).then(c => wrapFunctional(c.default || c))
export const MarketAnalysisKpis = () => import('../../components/marketAnalysis/MarketAnalysisKpis.vue' /* webpackChunkName: "components/market-analysis-kpis" */).then(c => wrapFunctional(c.default || c))
export const MarketAnalysisPage = () => import('../../components/marketAnalysis/MarketAnalysisPage.vue' /* webpackChunkName: "components/market-analysis-page" */).then(c => wrapFunctional(c.default || c))
export const MarketAnalysisPerformance = () => import('../../components/marketAnalysis/MarketAnalysisPerformance.vue' /* webpackChunkName: "components/market-analysis-performance" */).then(c => wrapFunctional(c.default || c))
export const MarketKpiCard = () => import('../../components/marketAnalysis/MarketKpiCard.vue' /* webpackChunkName: "components/market-kpi-card" */).then(c => wrapFunctional(c.default || c))
export const MarketSummaryCard = () => import('../../components/marketAnalysis/MarketSummaryCard.vue' /* webpackChunkName: "components/market-summary-card" */).then(c => wrapFunctional(c.default || c))
export const MarketAnalysisPersonalizedMarketReportCard = () => import('../../components/marketAnalysis/PersonalizedMarketReportCard.vue' /* webpackChunkName: "components/market-analysis-personalized-market-report-card" */).then(c => wrapFunctional(c.default || c))
export const MarketAnalysisVolatilityIndexChart = () => import('../../components/marketAnalysis/VolatilityIndexChart.vue' /* webpackChunkName: "components/market-analysis-volatility-index-chart" */).then(c => wrapFunctional(c.default || c))
export const PortfolioAddPortfolio = () => import('../../components/portfolio/AddPortfolio.vue' /* webpackChunkName: "components/portfolio-add-portfolio" */).then(c => wrapFunctional(c.default || c))
export const PortfolioAddPositionToPortfolio = () => import('../../components/portfolio/AddPositionToPortfolio.vue' /* webpackChunkName: "components/portfolio-add-position-to-portfolio" */).then(c => wrapFunctional(c.default || c))
export const PortfolioAllocationConstituents = () => import('../../components/portfolio/AllocationConstituents.vue' /* webpackChunkName: "components/portfolio-allocation-constituents" */).then(c => wrapFunctional(c.default || c))
export const PortfolioArchivePortfolio = () => import('../../components/portfolio/ArchivePortfolio.vue' /* webpackChunkName: "components/portfolio-archive-portfolio" */).then(c => wrapFunctional(c.default || c))
export const PortfolioChangePortfolioCurrency = () => import('../../components/portfolio/ChangePortfolioCurrency.vue' /* webpackChunkName: "components/portfolio-change-portfolio-currency" */).then(c => wrapFunctional(c.default || c))
export const PortfolioCustomPortfolioIcon = () => import('../../components/portfolio/CustomPortfolioIcon.vue' /* webpackChunkName: "components/portfolio-custom-portfolio-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioDashboardPosition = () => import('../../components/portfolio/DashboardPosition.vue' /* webpackChunkName: "components/portfolio-dashboard-position" */).then(c => wrapFunctional(c.default || c))
export const PortfolioDeletePortfolio = () => import('../../components/portfolio/DeletePortfolio.vue' /* webpackChunkName: "components/portfolio-delete-portfolio" */).then(c => wrapFunctional(c.default || c))
export const PortfolioFinApiDisclaimer = () => import('../../components/portfolio/FinApiDisclaimer.vue' /* webpackChunkName: "components/portfolio-fin-api-disclaimer" */).then(c => wrapFunctional(c.default || c))
export const PortfolioImportPortfolioPositions = () => import('../../components/portfolio/ImportPortfolioPositions.vue' /* webpackChunkName: "components/portfolio-import-portfolio-positions" */).then(c => wrapFunctional(c.default || c))
export const PortfolioLivePortfolioActions = () => import('../../components/portfolio/LivePortfolioActions.vue' /* webpackChunkName: "components/portfolio-live-portfolio-actions" */).then(c => wrapFunctional(c.default || c))
export const PortfolioLivePortfolioImport = () => import('../../components/portfolio/LivePortfolioImport.vue' /* webpackChunkName: "components/portfolio-live-portfolio-import" */).then(c => wrapFunctional(c.default || c))
export const Portfolio = () => import('../../components/portfolio/Portfolio.vue' /* webpackChunkName: "components/portfolio" */).then(c => wrapFunctional(c.default || c))
export const PortfolioAllocation = () => import('../../components/portfolio/PortfolioAllocation.vue' /* webpackChunkName: "components/portfolio-allocation" */).then(c => wrapFunctional(c.default || c))
export const PortfolioDashboard = () => import('../../components/portfolio/PortfolioDashboard.vue' /* webpackChunkName: "components/portfolio-dashboard" */).then(c => wrapFunctional(c.default || c))
export const PortfolioKpi = () => import('../../components/portfolio/PortfolioKpi.vue' /* webpackChunkName: "components/portfolio-kpi" */).then(c => wrapFunctional(c.default || c))
export const PortfolioKpiCard = () => import('../../components/portfolio/PortfolioKpiCard.vue' /* webpackChunkName: "components/portfolio-kpi-card" */).then(c => wrapFunctional(c.default || c))
export const PortfolioMenu = () => import('../../components/portfolio/PortfolioMenu.vue' /* webpackChunkName: "components/portfolio-menu" */).then(c => wrapFunctional(c.default || c))
export const PortfolioPerformance = () => import('../../components/portfolio/PortfolioPerformance.vue' /* webpackChunkName: "components/portfolio-performance" */).then(c => wrapFunctional(c.default || c))
export const PortfolioSecondaryKpi = () => import('../../components/portfolio/PortfolioSecondaryKpi.vue' /* webpackChunkName: "components/portfolio-secondary-kpi" */).then(c => wrapFunctional(c.default || c))
export const PortfolioTab = () => import('../../components/portfolio/PortfolioTab.vue' /* webpackChunkName: "components/portfolio-tab" */).then(c => wrapFunctional(c.default || c))
export const PortfolioTabs = () => import('../../components/portfolio/PortfolioTabs.vue' /* webpackChunkName: "components/portfolio-tabs" */).then(c => wrapFunctional(c.default || c))
export const PortfolioTransactions = () => import('../../components/portfolio/PortfolioTransactions.vue' /* webpackChunkName: "components/portfolio-transactions" */).then(c => wrapFunctional(c.default || c))
export const PortfolioQuote = () => import('../../components/portfolio/Quote.vue' /* webpackChunkName: "components/portfolio-quote" */).then(c => wrapFunctional(c.default || c))
export const PortfolioRealizedKpiCard = () => import('../../components/portfolio/RealizedKpiCard.vue' /* webpackChunkName: "components/portfolio-realized-kpi-card" */).then(c => wrapFunctional(c.default || c))
export const PortfolioRenamePortfolio = () => import('../../components/portfolio/RenamePortfolio.vue' /* webpackChunkName: "components/portfolio-rename-portfolio" */).then(c => wrapFunctional(c.default || c))
export const PortfolioReturnValueKpiCard = () => import('../../components/portfolio/ReturnValueKpiCard.vue' /* webpackChunkName: "components/portfolio-return-value-kpi-card" */).then(c => wrapFunctional(c.default || c))
export const PortfolioSharePortfolioSwitch = () => import('../../components/portfolio/SharePortfolioSwitch.vue' /* webpackChunkName: "components/portfolio-share-portfolio-switch" */).then(c => wrapFunctional(c.default || c))
export const PortfolioStandaloneFileImport = () => import('../../components/portfolio/StandaloneFileImport.vue' /* webpackChunkName: "components/portfolio-standalone-file-import" */).then(c => wrapFunctional(c.default || c))
export const PortfolioSubscribeToPortfolio = () => import('../../components/portfolio/SubscribeToPortfolio.vue' /* webpackChunkName: "components/portfolio-subscribe-to-portfolio" */).then(c => wrapFunctional(c.default || c))
export const PortfolioUpdatePurchasePriceTransaction = () => import('../../components/portfolio/UpdatePurchasePriceTransaction.vue' /* webpackChunkName: "components/portfolio-update-purchase-price-transaction" */).then(c => wrapFunctional(c.default || c))
export const ProfileUpdateProfileAvatar = () => import('../../components/profile/UpdateProfileAvatar.vue' /* webpackChunkName: "components/profile-update-profile-avatar" */).then(c => wrapFunctional(c.default || c))
export const ShareInvestedFunds = () => import('../../components/share/InvestedFunds.vue' /* webpackChunkName: "components/share-invested-funds" */).then(c => wrapFunctional(c.default || c))
export const ShareCopyClipboard = () => import('../../components/share/ShareCopyClipboard.vue' /* webpackChunkName: "components/share-copy-clipboard" */).then(c => wrapFunctional(c.default || c))
export const ShareDetails = () => import('../../components/share/ShareDetails.vue' /* webpackChunkName: "components/share-details" */).then(c => wrapFunctional(c.default || c))
export const ShareDividends = () => import('../../components/share/ShareDividends.vue' /* webpackChunkName: "components/share-dividends" */).then(c => wrapFunctional(c.default || c))
export const ShareFundamentals = () => import('../../components/share/ShareFundamentals.vue' /* webpackChunkName: "components/share-fundamentals" */).then(c => wrapFunctional(c.default || c))
export const ShareIdChanges = () => import('../../components/share/ShareIdChanges.vue' /* webpackChunkName: "components/share-id-changes" */).then(c => wrapFunctional(c.default || c))
export const ShareNews = () => import('../../components/share/ShareNews.vue' /* webpackChunkName: "components/share-news" */).then(c => wrapFunctional(c.default || c))
export const SharePage = () => import('../../components/share/SharePage.vue' /* webpackChunkName: "components/share-page" */).then(c => wrapFunctional(c.default || c))
export const SharePerformance = () => import('../../components/share/SharePerformance.vue' /* webpackChunkName: "components/share-performance" */).then(c => wrapFunctional(c.default || c))
export const ShareProfile = () => import('../../components/share/ShareProfile.vue' /* webpackChunkName: "components/share-profile" */).then(c => wrapFunctional(c.default || c))
export const ShareQuotes = () => import('../../components/share/ShareQuotes.vue' /* webpackChunkName: "components/share-quotes" */).then(c => wrapFunctional(c.default || c))
export const ShareSimilarShares = () => import('../../components/share/ShareSimilarShares.vue' /* webpackChunkName: "components/share-similar-shares" */).then(c => wrapFunctional(c.default || c))
export const ShareSplits = () => import('../../components/share/ShareSplits.vue' /* webpackChunkName: "components/share-splits" */).then(c => wrapFunctional(c.default || c))
export const ShareTickers = () => import('../../components/share/ShareTickers.vue' /* webpackChunkName: "components/share-tickers" */).then(c => wrapFunctional(c.default || c))
export const SharedChartLegendIcon = () => import('../../components/shared/ChartLegendIcon.vue' /* webpackChunkName: "components/shared-chart-legend-icon" */).then(c => wrapFunctional(c.default || c))
export const SharedMobileDialog = () => import('../../components/shared/MobileDialog.vue' /* webpackChunkName: "components/shared-mobile-dialog" */).then(c => wrapFunctional(c.default || c))
export const SharedPercentBox = () => import('../../components/shared/PercentBox.vue' /* webpackChunkName: "components/shared-percent-box" */).then(c => wrapFunctional(c.default || c))
export const SharedPrimaryButton = () => import('../../components/shared/PrimaryButton.vue' /* webpackChunkName: "components/shared-primary-button" */).then(c => wrapFunctional(c.default || c))
export const SharedSnackBar = () => import('../../components/shared/SnackBar.vue' /* webpackChunkName: "components/shared-snack-bar" */).then(c => wrapFunctional(c.default || c))
export const SharedTextToggleSwitch = () => import('../../components/shared/TextToggleSwitch.vue' /* webpackChunkName: "components/shared-text-toggle-switch" */).then(c => wrapFunctional(c.default || c))
export const TooltipsAllocationPercentTableTooltip = () => import('../../components/tooltips/AllocationPercentTableTooltip.vue' /* webpackChunkName: "components/tooltips-allocation-percent-table-tooltip" */).then(c => wrapFunctional(c.default || c))
export const TooltipsAllocationProfitAndLostPercentTableTooltip = () => import('../../components/tooltips/AllocationProfitAndLostPercentTableTooltip.vue' /* webpackChunkName: "components/tooltips-allocation-profit-and-lost-percent-table-tooltip" */).then(c => wrapFunctional(c.default || c))
export const TooltipsAllocationValueTableTooltip = () => import('../../components/tooltips/AllocationValueTableTooltip.vue' /* webpackChunkName: "components/tooltips-allocation-value-table-tooltip" */).then(c => wrapFunctional(c.default || c))
export const TooltipsPositionCostsTable = () => import('../../components/tooltips/PositionCostsTable.vue' /* webpackChunkName: "components/tooltips-position-costs-table" */).then(c => wrapFunctional(c.default || c))
export const TooltipsPositionCurrentValueTable = () => import('../../components/tooltips/PositionCurrentValueTable.vue' /* webpackChunkName: "components/tooltips-position-current-value-table" */).then(c => wrapFunctional(c.default || c))
export const TooltipsPositionGenericTableTooltip = () => import('../../components/tooltips/PositionGenericTableTooltip.vue' /* webpackChunkName: "components/tooltips-position-generic-table-tooltip" */).then(c => wrapFunctional(c.default || c))
export const TooltipsPositionPeriodStartValueTable = () => import('../../components/tooltips/PositionPeriodStartValueTable.vue' /* webpackChunkName: "components/tooltips-position-period-start-value-table" */).then(c => wrapFunctional(c.default || c))
export const TooltipsPositionPriceTooltip = () => import('../../components/tooltips/PositionPriceTooltip.vue' /* webpackChunkName: "components/tooltips-position-price-tooltip" */).then(c => wrapFunctional(c.default || c))
export const TooltipsPositionProfitAndLossPercentTable = () => import('../../components/tooltips/PositionProfitAndLossPercentTable.vue' /* webpackChunkName: "components/tooltips-position-profit-and-loss-percent-table" */).then(c => wrapFunctional(c.default || c))
export const TooltipsPositionProfitAndLossTable = () => import('../../components/tooltips/PositionProfitAndLossTable.vue' /* webpackChunkName: "components/tooltips-position-profit-and-loss-table" */).then(c => wrapFunctional(c.default || c))
export const TooltipsPositionRoiTable = () => import('../../components/tooltips/PositionRoiTable.vue' /* webpackChunkName: "components/tooltips-position-roi-table" */).then(c => wrapFunctional(c.default || c))
export const TooltipsRealizedCostTableTooltip = () => import('../../components/tooltips/RealizedCostTableTooltip.vue' /* webpackChunkName: "components/tooltips-realized-cost-table-tooltip" */).then(c => wrapFunctional(c.default || c))
export const TooltipsRealizedDividendTableTooltip = () => import('../../components/tooltips/RealizedDividendTableTooltip.vue' /* webpackChunkName: "components/tooltips-realized-dividend-table-tooltip" */).then(c => wrapFunctional(c.default || c))
export const TooltipsRealizedPurchasePriceTooltip = () => import('../../components/tooltips/RealizedPurchasePriceTooltip.vue' /* webpackChunkName: "components/tooltips-realized-purchase-price-tooltip" */).then(c => wrapFunctional(c.default || c))
export const TooltipsRealizedRoiRelTableTooltip = () => import('../../components/tooltips/RealizedRoiRelTableTooltip.vue' /* webpackChunkName: "components/tooltips-realized-roi-rel-table-tooltip" */).then(c => wrapFunctional(c.default || c))
export const TooltipsRealizedRoiTableTooltip = () => import('../../components/tooltips/RealizedRoiTableTooltip.vue' /* webpackChunkName: "components/tooltips-realized-roi-table-tooltip" */).then(c => wrapFunctional(c.default || c))
export const TooltipsRealizedSalePriceTableTooltip = () => import('../../components/tooltips/RealizedSalePriceTableTooltip.vue' /* webpackChunkName: "components/tooltips-realized-sale-price-table-tooltip" */).then(c => wrapFunctional(c.default || c))
export const TooltipsTooltipPopup = () => import('../../components/tooltips/TooltipPopup.vue' /* webpackChunkName: "components/tooltips-tooltip-popup" */).then(c => wrapFunctional(c.default || c))
export const TooltipsTooltipPopupAllocation = () => import('../../components/tooltips/TooltipPopupAllocation.vue' /* webpackChunkName: "components/tooltips-tooltip-popup-allocation" */).then(c => wrapFunctional(c.default || c))
export const TooltipsTooltipPopupPerformance = () => import('../../components/tooltips/TooltipPopupPerformance.vue' /* webpackChunkName: "components/tooltips-tooltip-popup-performance" */).then(c => wrapFunctional(c.default || c))
export const TooltipsTooltipPopupRealized = () => import('../../components/tooltips/TooltipPopupRealized.vue' /* webpackChunkName: "components/tooltips-tooltip-popup-realized" */).then(c => wrapFunctional(c.default || c))
export const TransactionAddTransactionToPosition = () => import('../../components/transaction/AddTransactionToPosition.vue' /* webpackChunkName: "components/transaction-add-transaction-to-position" */).then(c => wrapFunctional(c.default || c))
export const TransactionListTransactions = () => import('../../components/transaction/ListTransactions.vue' /* webpackChunkName: "components/transaction-list-transactions" */).then(c => wrapFunctional(c.default || c))
export const TransactionDelete = () => import('../../components/transaction/TransactionDelete.vue' /* webpackChunkName: "components/transaction-delete" */).then(c => wrapFunctional(c.default || c))
export const TransactionTypeIcon = () => import('../../components/transaction/TransactionTypeIcon.vue' /* webpackChunkName: "components/transaction-type-icon" */).then(c => wrapFunctional(c.default || c))
export const TransactionTransactionsProfitLoss = () => import('../../components/transaction/TransactionsProfitLoss.vue' /* webpackChunkName: "components/transaction-transactions-profit-loss" */).then(c => wrapFunctional(c.default || c))
export const PortfolioAssetTypeSelector = () => import('../../components/portfolio/asset/PortfolioAssetTypeSelector.vue' /* webpackChunkName: "components/portfolio-asset-type-selector" */).then(c => wrapFunctional(c.default || c))
export const PortfolioCreateIntroductionCreatePortfolioModal = () => import('../../components/portfolio/create/IntroductionCreatePortfolioModal.vue' /* webpackChunkName: "components/portfolio-create-introduction-create-portfolio-modal" */).then(c => wrapFunctional(c.default || c))
export const PortfolioCreateIntroductionFuncionalityPortfolioModal = () => import('../../components/portfolio/create/IntroductionFuncionalityPortfolioModal.vue' /* webpackChunkName: "components/portfolio-create-introduction-funcionality-portfolio-modal" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsAtomIcon = () => import('../../components/portfolio/icons/AtomIcon.vue' /* webpackChunkName: "components/portfolio-icons-atom-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsBankIcon = () => import('../../components/portfolio/icons/BankIcon.vue' /* webpackChunkName: "components/portfolio-icons-bank-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsBarChartCenterAlignedAlt3dIcon = () => import('../../components/portfolio/icons/BarChartCenterAlignedAlt3dIcon.vue' /* webpackChunkName: "components/portfolio-icons-bar-chart-center-aligned-alt3d-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsBootHeeledIcon = () => import('../../components/portfolio/icons/BootHeeledIcon.vue' /* webpackChunkName: "components/portfolio-icons-boot-heeled-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsBriefcaseIcon = () => import('../../components/portfolio/icons/BriefcaseIcon.vue' /* webpackChunkName: "components/portfolio-icons-briefcase-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsBuildingIcon = () => import('../../components/portfolio/icons/BuildingIcon.vue' /* webpackChunkName: "components/portfolio-icons-building-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsBuildingStoreIcon = () => import('../../components/portfolio/icons/BuildingStoreIcon.vue' /* webpackChunkName: "components/portfolio-icons-building-store-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsCameraIcon = () => import('../../components/portfolio/icons/CameraIcon.vue' /* webpackChunkName: "components/portfolio-icons-camera-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsCarRearIcon = () => import('../../components/portfolio/icons/CarRearIcon.vue' /* webpackChunkName: "components/portfolio-icons-car-rear-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsCouchIcon = () => import('../../components/portfolio/icons/CouchIcon.vue' /* webpackChunkName: "components/portfolio-icons-couch-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsCreditCardIcon = () => import('../../components/portfolio/icons/CreditCardIcon.vue' /* webpackChunkName: "components/portfolio-icons-credit-card-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsCurrencyBitcoinCircleIcon = () => import('../../components/portfolio/icons/CurrencyBitcoinCircleIcon.vue' /* webpackChunkName: "components/portfolio-icons-currency-bitcoin-circle-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsDropletsIcon = () => import('../../components/portfolio/icons/DropletsIcon.vue' /* webpackChunkName: "components/portfolio-icons-droplets-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsGiftIcon = () => import('../../components/portfolio/icons/GiftIcon.vue' /* webpackChunkName: "components/portfolio-icons-gift-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsGlobalMapIcon = () => import('../../components/portfolio/icons/GlobalMapIcon.vue' /* webpackChunkName: "components/portfolio-icons-global-map-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsGraduationHatIcon = () => import('../../components/portfolio/icons/GraduationHatIcon.vue' /* webpackChunkName: "components/portfolio-icons-graduation-hat-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsHomeIcon = () => import('../../components/portfolio/icons/HomeIcon.vue' /* webpackChunkName: "components/portfolio-icons-home-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsLightEmergencyOnIcon = () => import('../../components/portfolio/icons/LightEmergencyOnIcon.vue' /* webpackChunkName: "components/portfolio-icons-light-emergency-on-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsMonitorIcon = () => import('../../components/portfolio/icons/MonitorIcon.vue' /* webpackChunkName: "components/portfolio-icons-monitor-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsPhoneCallIcon = () => import('../../components/portfolio/icons/PhoneCallIcon.vue' /* webpackChunkName: "components/portfolio-icons-phone-call-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsPlaneIcon = () => import('../../components/portfolio/icons/PlaneIcon.vue' /* webpackChunkName: "components/portfolio-icons-plane-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconGenerator = () => import('../../components/portfolio/icons/PortfolioIconGenerator.vue' /* webpackChunkName: "components/portfolio-icon-generator" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsRingDiamondIcon = () => import('../../components/portfolio/icons/RingDiamondIcon.vue' /* webpackChunkName: "components/portfolio-icons-ring-diamond-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsRoadIcon = () => import('../../components/portfolio/icons/RoadIcon.vue' /* webpackChunkName: "components/portfolio-icons-road-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsSafeIcon = () => import('../../components/portfolio/icons/SafeIcon.vue' /* webpackChunkName: "components/portfolio-icons-safe-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsShirtLongSleeveIcon = () => import('../../components/portfolio/icons/ShirtLongSleeveIcon.vue' /* webpackChunkName: "components/portfolio-icons-shirt-long-sleeve-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsShoppingBagIcon = () => import('../../components/portfolio/icons/ShoppingBagIcon.vue' /* webpackChunkName: "components/portfolio-icons-shopping-bag-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsTargetIcon = () => import('../../components/portfolio/icons/TargetIcon.vue' /* webpackChunkName: "components/portfolio-icons-target-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsToolIcon = () => import('../../components/portfolio/icons/ToolIcon.vue' /* webpackChunkName: "components/portfolio-icons-tool-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsUserIcon = () => import('../../components/portfolio/icons/UserIcon.vue' /* webpackChunkName: "components/portfolio-icons-user-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIconsWalletIcon = () => import('../../components/portfolio/icons/WalletIcon.vue' /* webpackChunkName: "components/portfolio-icons-wallet-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIntroductionSandboxModal = () => import('../../components/portfolio/introduction/IntroductionSandboxModal.vue' /* webpackChunkName: "components/portfolio-introduction-sandbox-modal" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIntroductionTourModal = () => import('../../components/portfolio/introduction/IntroductionTourModal.vue' /* webpackChunkName: "components/portfolio-introduction-tour-modal" */).then(c => wrapFunctional(c.default || c))
export const PortfolioPositionDeletePortfolioPosition = () => import('../../components/portfolio/position/DeletePortfolioPosition.vue' /* webpackChunkName: "components/portfolio-position-delete-portfolio-position" */).then(c => wrapFunctional(c.default || c))
export const PortfolioPositionEditPortfolioPosition = () => import('../../components/portfolio/position/EditPortfolioPosition.vue' /* webpackChunkName: "components/portfolio-position-edit-portfolio-position" */).then(c => wrapFunctional(c.default || c))
export const PortfolioPositionGlobalPosition = () => import('../../components/portfolio/position/GlobalPosition.vue' /* webpackChunkName: "components/portfolio-position-global-position" */).then(c => wrapFunctional(c.default || c))
export const PortfolioPositionMovePortfolioPosition = () => import('../../components/portfolio/position/MovePortfolioPosition.vue' /* webpackChunkName: "components/portfolio-position-move-portfolio-position" */).then(c => wrapFunctional(c.default || c))
export const PortfolioPosition = () => import('../../components/portfolio/position/PortfolioPosition.vue' /* webpackChunkName: "components/portfolio-position" */).then(c => wrapFunctional(c.default || c))
export const PortfolioPositions = () => import('../../components/portfolio/position/PortfolioPositions.vue' /* webpackChunkName: "components/portfolio-positions" */).then(c => wrapFunctional(c.default || c))
export const PortfolioPositionMenu = () => import('../../components/portfolio/position/PositionMenu.vue' /* webpackChunkName: "components/portfolio-position-menu" */).then(c => wrapFunctional(c.default || c))
export const PortfolioPositionTableColumnsConfig = () => import('../../components/portfolio/position/PositionTableColumnsConfig.vue' /* webpackChunkName: "components/portfolio-position-table-columns-config" */).then(c => wrapFunctional(c.default || c))
export const ShareFundData = () => import('../../components/share/fund/FundData.vue' /* webpackChunkName: "components/share-fund-data" */).then(c => wrapFunctional(c.default || c))
export const ShareFundTables = () => import('../../components/share/fund/FundTables.vue' /* webpackChunkName: "components/share-fund-tables" */).then(c => wrapFunctional(c.default || c))
export const ShareFundFundamentalsSheet = () => import('../../components/share/fund/FundamentalsSheet.vue' /* webpackChunkName: "components/share-fund-fundamentals-sheet" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
